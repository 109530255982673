import Control from './control';
import Page from './page';
import all from './control/list'
import './style.css';
import Burger from 'skin/table/content/command/control/burger';

const Command = ({ action, page, pages, setPage, data, mb }) => {
	let cls = ['skin-cmptbl-command', 'cmptbl-command'];
	cls = cls.join(' ');
	console.log('pages', pages)
	return (
		<div className={cls}>
			{!mb && 
			<>
			<Control action={action} data={data} />
			{pages > 1 && <Page page={page} pages={pages} action={action} setPage={setPage} /> }
			{pages < 2 && <div></div>}
			</>}
			{mb &&
			<>
			{pages > 1 && <Page page={page} pages={pages} action={action} setPage={setPage} /> }
			{pages < 2 && <div></div>}
			<Burger all = {all}  click = {click}/>
			</>}
			
		</div>
	);

	function click(item) {
		const o = {
			type: 'control',
			action: item,
			table: data.code,
		};
		action(o);
	}
};

export default Command;
