import { useRef, useEffect } from "react"



export const List = ({onClose, count}) => {
    const ref = useRef();

    useEffect(() => {
		const click = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClose();
			}
		};
		setTimeout(() => { document.addEventListener('click', click); });
	  
		return () =>  document.removeEventListener('click', click);
	
	}, []);

    return ( 
        <div ref={ref} className="skin-hdr-r-message-list">
            <div className="skin-hdr-r-message-list-cont">
                {count < 1 && <div className="skin-hdr-r-message-list-empt">У вас пока нет сообщений</div>}
            </div>
        </div>
    )
}