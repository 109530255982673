import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Empty from 'skin/cmp/empty';
import Plg from './polygon';
import Pln from './polyline';
import './style.css';

const Map = ({ s, v }) => {
	const { height = '400px', label, name } = s;
	let cls = ['skin-map', 'map'];
	cls = cls.join(' ');
	if (!v) {
		return <Empty />;
	}

	const polygon = v.polygons
		? v.polygons.map((e, i) => {
				return <Plg key={'polygon_' + i} el={e} i={i} />;
		  })
		: null;

	const points = v.points
		? v.points.map((el, i) => {
				return (
					<Marker position={el.geo} key={'point_' + i}>
						<Popup>{el.name}</Popup>
					</Marker>
				);
		  })
		: null;

	const line = v.line ? <Pln el={v.line} /> : null;

	const lines = v.lines
		? v.lines.map((el, i) => {
				return <Pln el={el} i={i} key={'lines_' + i} />;
		  })
		: null;

	const title = label;
	const style = {
		height,
	};
	const center = v?.geo?.center ?? ['48.766883780301065', '44.79892694735944'];
	const zoom = v?.geo?.zoom ?? 14;
	const scroll = v?.geo?.scroll ?? false;

	return (
		<div className={cls} style={style} name={name}>
			{title && <div className="title">{title}</div>}
			<MapContainer center={center} zoom={zoom} scrollWheelZoom={scroll}>
				<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
				{polygon}
				{points}
				{line}
				{lines}
			</MapContainer>
		</div>
	);
};
export default Map;
