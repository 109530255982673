import './style.css'

const Field = ({id, title, placeholder, type, img, required, act, mb = false}) => {
    return (
        <div className='login-main-field-cont'>
            <label
                htmlFor={id}
                className='login-main-label'
            >
                {title}
            </label>
            <div className='login-main-field'>
                <input
                    id={id}
                    className='login-main-input'
                    placeholder={placeholder}
                    onChange={e => act(e.target.value)}
                    type={type}
                    required
                />
                {img && <div
                    className='login-main-img'
                    style={{ backgroundImage: `url(${img})` }}
                ></div>}
            </div>
        </div>
    )
}

export default Field