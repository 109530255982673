import { dictionary } from 'skin/event';

import styles from './styles.module.css'
import './style.css'

const FieldD = ({ id, title, placeholder, type, img, required, act, dCode, dText, disabled }) => {
    const ico = dCode ? (
        <div className='login-main-list' onClick={icoClick}>
            <img className='login-main-ico' src='task/img/city_p.svg' alt=""/>
            <div className='login-main-dtext'>{dText}</div>
        </div>
    ) : null

    return (
        <div className='login-main-field-cont'>
            <label
                htmlFor={id}
                className='login-main-label'
            >
                {title}
                {required && <span className='login-main-star'>*</span>}
            </label>
            <div className='login-main-field'>
                <input
                    id={id}
                    className='login-main-input'
                    placeholder={placeholder}
                    onChange={onChange}
                    type={type}
                    required
                    disabled={disabled}
                />
                {img && <div
                    className='login-main-img'
                    onClick={icoClick}
                    style={{ backgroundImage: `url(${img})` }}
                ></div>}
                {ico}
            </div>
        </div>
    )

    function onChange(e) {
        act(e.target.value)
        const name = document.getElementById('phone');
        name.oninput = function () {
            if (this.value.length < 2) return
            const matrix = "+7(___)-___-__-__"
            let i = 0
            const def = matrix.replace(/\D/g, "")
            const val = this.value.replace(/\D/g, "")
            let newV = matrix.replace(/[_\d]/g, function (a) {
                return i < val.length ? val.charAt(i++) || def.charAt(i) : a
            });
            const pos = { 3: 2, 8: 6, 12: 11, 15: 14 }
            i = newV.indexOf('_')
            if (i !== -1) {
                i = pos[i] ?? i
                newV = newV.slice(0, i)
                this.value = newV
            }
        }

    }

    function icoClick() {
        if (!dCode) return;
        // Отображаем дешифратор
        const o = { code: dCode, func: setV, unauth: true };
        dictionary(o);
        function setV(v) {
            const fld = document.getElementById(id)
            fld.value = v
            act(v)
        }

    }
}

export default FieldD

