import { useEffect } from "react";
import { space } from 'skin/event';
export default function useParams() {
	// const { action } = useContext(Cnt);
	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		if (!params.size) return null
		console.log('params', params.get('orderId'))
		const o = {
			type: 'table',
			title: 'Пополнение',
			data: {
				code: 'bill',
			},
		};
		space(o, true);
		window.history.replaceState(null, '', `${window.location.pathname}`);
	}, [])
	
}