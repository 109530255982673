import { useRef, useEffect } from "react"
import Logout from "../../right/logout";
import Message from "../../right/message";
import user from 'skin/store/user';
import Personal from "../../right/personal";
import Balance from "../../right/balance";
import data from "../../../../../config_task"
import cnfTask from 'config_task';
import m from "skin/store/mb";
import { observer } from "mobx-react-lite";

const List = ({onClose}) => {
    const ref = useRef();

	const mb = m.isMobile;

    useEffect(() => {
		user.get();
	}, []);

    useEffect(() => {
		const click = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClose();
			}
		};
		setTimeout(() => { document.addEventListener('click', click); });
	  
		return () =>  document.removeEventListener('click', click);
	
	}, []);

	const st = { backgroundImage: 'url(skin/img/doc.svg)' };
	const link = data.docs.map((el) => {
		const href = data.url + el.file + ms();
		return (
			<div className="skin-header-burger-drop-user">
				<a
					key={el.file}
					href={href}
					target="_blank"
					rel="noopener noreferrer"
					style={st}
					className="ico-mb"
				>
				</a>
				<div >
				<p>{el.text}</p>
				</div>
			</div>
		);
	});

    return ( 
        <div ref={ref} className= "skin-header-burger-drop">
            <div className="skin-header-burger-drop-user">  
                <Personal avatar={user.avatar} info={[user.id]} />
			    <span className="user-name" title={user.name}>
				    {user.name}
			    </span>
                <Logout mb = {mb}/>
            </div>
            <div><Balance blc={user.blc} tar={user?.tar} adm={user.adm} mb = {mb} /></div>
			<div className="skin-header-burger-drop-user"><Message mb = {mb} /> <p>Сообщения</p></div>
			{link}
			<p style={{fontSize:'12px', textAlign:'center'}}>{cnfTask.copyright}</p>
        </div>
    )
}

function ms(){
	const t = new Date()
	return `?${t.getMinutes()}:${t.getSeconds()}`
}

export default observer(List);