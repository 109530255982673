import { List } from './list';
import './style.css';
import { useState } from 'react';

const Message = props => {
	let cls = ['skin-hdr-r-message', 'hdr-r-message', props.mb ? 'ico-mb' : 'ico'];
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/list.svg)' };
	const cnt = 0;
	const [msg, setMsg] = useState(false);
	const click = _ => setMsg(!msg);
	const close = _ => setMsg(false);
	return (
			<span
				className={cls}
				style={st}
				title='Сообщения'
				onClick={click}
			>
				{cnt > 0 && <div className= {'skin-hdr-r-message-count'}>{cnt}</div>}
				{msg && <List onClose = {close} count={cnt}></List>}
			</span>
	)
}

export default Message
