import { makeAutoObservable } from 'mobx';
import def from './def';
import delFile from './del_file';
import pickCell from './pick_cell';
import plgList from './plg_list';
import printPlc from './print_plc';
import prnBox from './prn_box';
import prnCell from './print_cell';
// import invoice from './invoice';
import arrToRem from './arr_rem';
import teg from './teg';
import sales from './sales';
import usAct from './us_act';
import pacMaster from './pac_master';
import msPacList from './pac_list';
import defTar from './def_tar';
import makeProd from './make_prod';
import parList from './par_list';
import prnCode from './print_code';
import msDeliv from './ms_deliv';
import syncFrom from './sync_from';
import syncTo from './sync_to';
import arrToPac from './arr_pac';
import msPack from './pac';
import msMod from './ms_mod';
import msProImg from './property';
import msProT from './pro_t';
import msProA from './pro_a';
import genFact from './gen/fact';
import genPcg from './gen/pcg';
import genNo from './gen/no';
import genStep from './gen/step';
import genCat from './gen_cat';
import codeDown from './code/download';
import msCSV from './ms_csv';
import prdCopy from './copy_prd';
import msFriend from './ms_friend';
import msPrdWriteoff from './ms_prd_writeoff';
import msCtgUp from './ctg_up';
import rewDown from './review/rew_down';
import rewUp from './review/rew_up';
import msCtgDwnl from './ms_ctg_dwnl';
import msRfmGroup from './ms_rfm_group';
import msSegment from './ms_segment';
import msData from './ms_data';
import msNoteUp from './ms_note_up';
import msProdDwnl from './prod/ms_prod_dwnl';
import msProdUp from './prod/ms_prod_up';
import rfmCompare from './rfm_compare';
import msDStatus from './ms_d_status';
import msProd from './product';
import msPickup from './ms_pickup'
import msExMrkt from './ms_ex'
import msSelectModAdm from './ms_select_mod_adm';
import msSelectModCli from './ms_select_mod_adm';
import msSelectMod from './ms_select_mod_adm';
import topup from './topup';
import remain from './remain';
import msProdMove from './ms_prod_move';

class Data {
	s = {
		def,
		delFile,
		pickCell,
		plgList,
		printPlc,
		prnBox,
		// invoice,
		prnCell,
		arrToRem,
		teg,
		sales,
		usAct,
		pacMaster,
		msPacList,
		defTar,
		makeProd,
		parList,
		prnCode,
		msDeliv,
		syncFrom,
		syncTo,
		arrToPac,
		msPack,
		msMod,
		msProImg,
		msProT,
		msProA,
		genFact,
		genPcg,
		genNo,
		genStep,
		genCat,
		codeDown,
		msCSV,
		prdCopy,
		msFriend,
		msPrdWriteoff,
		msCtgDwnl,
		msCtgUp,
		msRfmGroup,
		msSegment,
		msData,
		rewDown,
		rewUp,
		msProdDwnl,
		msProdUp,
		msNoteUp,
		rfmCompare,
		msDStatus,
		msProd,
		msPickup,
		msExMrkt,
		msSelectModAdm,
		msSelectModCli,
		msSelectMod,
		topup,
    remain,
    msProdMove
	};
	constructor(props) {
		makeAutoObservable(this);
	}
}

const data = new Data();
export default data;
