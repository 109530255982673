

//Ссылка
export default function Link({s, v}) {

	return (
        <a 
			href={v}
			target="_blank"
			style={{lineHeight: '40px'}}
		>
			{s.label}
		</a>
    )
}