import { makeAutoObservable, runInAction, toJS } from 'mobx';
import menu from 'skin/store/menu';
import cnfSkin from 'config_skin';
import { query } from './fn';
import { message, space, stackEdit } from 'skin/event';
import api from 'skin/http';

//import { head, view, col, body, func } from "./table_test";
//import action from "./table_action";

class Data {
	s = {};
	// s = {
	// 	head,
	// 	view,
	// 	col,
	// 	body,
	// 	func,
	// 	action,
	// };
	constructor(props) {
		makeAutoObservable(this);
		this.rowClick = this.rowClick.bind(this);
	}
	// Клик на меню функций
	fncClick(id) {
		const obj = this.s.func ?? {};
		let a = obj.list ?? [];
		a = a.slice();
		const i = a.findIndex((el) => el._id === id);
		if (i < 0) return;
		if (a[i].type === 'folder') {
			if (!menu.s.double) obj.current = id;
			if (obj.current === id) {
				a[i].open = true;
				obj.current = '';
				this.fncParent(id);
			}
			obj.current = id;
		} else {
			this.action(i);
		}
		this.s.list = a;
	}
	get title() {
		return this.s?.head?.title ?? 'Таблица';
	}
	// Запуск функции на исполнение
	action(n) {
		const obj = this.s.func ?? {};
		let o = obj.list[n];
		if (!menu.s.double) obj.current = o._id;
		if (obj.current !== o._id) {
			obj.current = o._id;
			return;
		}
		if (!o.action) {
			alert('Действие не определено');
			return;
		}
		o.action.title = o.action.title ?? o.title;
		o = toJS(o.action);
		space(o, true);
	}
	// Завершить работу с функциями
	close() {
		this.func();
	}
	// Выйти в начало меню
	fncHome() {
		const obj = this.s.func ?? {};
		obj.parent = '';
		obj.current = '';
	}
	// Выйти из папки
	fncUp(id) {
		const obj = this.s.func ?? {};
		if (!obj.parent) return;
		if (menu.s.double && id && obj.current !== id) {
			obj.current = id;
			return;
		}
		obj.current = this.s.parent;
		const a = obj.list;
		const o = a.find((el) => el._id === obj.parent);

		this.fncParent(o.parent);
	}
	// Установить нового родителя
	fncParent(id) {
		const obj = this.s.func ?? {};
		obj.parent = id ?? '';
	}
	// Функционал "Вкл/Выкл"
	func(on) {
		on = on ?? false;
		if (!this.s?.func?.list) {
			if (on) alert('Функций нет');
			return;
		}
		this.s.func.on = on;
	}
	// Клик по строке таблицы
	rowClick(id, story, prp) {
		let o = story.key;
		if (o !== id) {
			story.setKey(id);
			return;
		}
		o = this.findRow(id);
		if (!o) {
			setTimeout(() => {
				message(`Не выбрана запись`, this.title);
			}, 0);
			return;
		}
		const info = o?.info ?? [];
		this.callForm(prp, info);
	}
	// Найти выделенную строку
	findRow(id) {
		const o = this.s.body.list.find((e) => e.key === id);
		if (!o) return null;
		return o;
	}
	// Вызвать форму
	callForm(prp, info = []) {
		const code = this.s.form?.edit.code ?? prp.code;
		const title = this.s.form?.edit.title ?? 'Форма';
		const o = {
			type: 'form',
			title,
			data: {
				code,
				info,
			},
		};
		space(o, true);
	}
	// Выбор представлений Вкл/Выкл
	viewOn() {
		const o = this.s.view;
		o.on = !o.on;
	}
	// Вернуть текущее представление
	get view() {
		const o = this.s.view;
		return o.list[o.current];
	}
	// Получить данные по описанию таблицы
	getDef(value, action) {
		const self = this;
		const config = {
			method: 'get',
			url: cnfSkin.url + 'api/table',
			params: { value },
		};
		api(config)
			.then(function (response) {
				const r = response.data.result;
				if (r.book?.title) stackEdit(r.book.title);
				runInAction(() => {
					for (let key in r) self.s[key] = r[key];
					const o = {
						type: 'table',
						action: 'loadDef',
					};
					action(o);
				});
			})
			.catch(console.log);
	}
	// Получить данные по описанию таблицы
	getData(prm, story, action) {
		const self = this;
		const page = story.page;
		let view = story.view;
		view = this.s.view.list[view];
		if (!view) return;
		let flt = this.s.col.filter((el) => el.viewId === view._id);
		const sort = getSort(flt);
		//flt = flt.map(el => ({name: el.name, type: el.type, data: el.fltData}))
		const q = query(story.filter);
		view = view.code ?? '';
		let value = {
			query: q,
			sort,
			page,
		};
		value = JSON.stringify(value);
		const config = {
			method: 'get',
			url: `api/data/table/${prm.code}/${view}`,
			params: { value },
		};
		api(config)
			.then(function (response) {
				const r = response.data.result;
				runInAction(() => {
					for (let key in r) self.s.body[key] = r[key];
					const o = {
						type: 'table',
						action: 'loadData',
					};
					action(o);
				});
			})
			.catch(console.log);
	}
	// Удаление записи из таблицы
	del(prp, story, action) {
		const self = this;
		const code = story.s.current;
		const key = story.key;
		const o = this.findRow(key);
		if (!o) {
			setTimeout(() => {
				message(`Не выбрана запись для удаления`, this.title);
			}, 0);
			return;
		}
		let value = o.info ?? [];
		value = JSON.stringify(value);
		const config = {
			method: 'delete',
			url: `api/del/${code}`,
			params: { value },
		};
		api(config)
			.then(function (response) {
				const err = response.data.error;
				if (err) return message(err, self.title, null, '!');
				self.getData(prp, story, action);
			})
			.catch(console.log);
	}
	add(prp, story, action) {
		const mesTitle = this.s?.head.title;
		const self = this;
		const code = story.s.current;
		const master = this.s.master.add;
		if (master) {
			const o = {
				type: 'master',
				title: 'Добавление ' + mesTitle,
				data: {
					code: master,
					form: code,
				},
			};
			return space(o, true);
		}
		const config = {
			method: 'post',
			url: `api/add/${code}`,
		};
		api(config)
			.then(function (response) {
				const info = response.data.result;
				if (info.error) return message(info.error, mesTitle, null, '!');
				self.callForm(prp, info);
			})
			.catch(console.log);
	}
}
//
function getSort(col) {
	col = col.filter((el) => el.sort);
	col = col.sort((a, b) => Math.abs(a.sort) - Math.abs(b.sort));
	const s = {};
	for (let i = 0; i < col.length; i++) {
		s[col[i].name] = col[i].sort > 0 ? 1 : -1;
	}
	return s;
}

const data = new Data();
export default data;
